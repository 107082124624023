.main_enteriesSec {
    padding: 20px;
}

.main_entry {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.main_entry {
    border-bottom: 1px solid #dfdfdf;
    padding: 15px;
}

.main_entry p {
    margin: 0;
}

.main_enteriesSec p {
    margin: 0 !important;
}

/* .Mindex {
    width: 5%;
}

.Mthumb {
    width: 10%;
} */

.Mthumb img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    display: block;
    margin: auto;
}

.main_table tbody tr:nth-of-type(even) {
    background: ghostwhite;
}

/* .Mname {
    width: 30%;
}

.Mgender {
    width: 10%;
}

.Mmarital {
    width: 10%;
}

.Mcountry {
    width: 15%;
} */

.Mactions {
    text-align: right;
}

.main_table {
    width: 100%;
}

.main_table thead {
    background: #D057A6;
}

.main_table thead tr th {
    padding: 8px 12px;
    color: #fff;
    font-weight: 400;
}

.main_table tbody tr td {
    padding: 8px 12px;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    text-transform: capitalize;
}

.main_table tbody tr td:first-of-type {
    border-left: 1px solid #dfdfdf;
}

.main_table tbody tr td i {
    margin-right: 20px;
    cursor: pointer;
}

.main_table tbody tr td i.fa-eye {
    color: #D057A6;
}

.main_table tbody tr td i.fa-pencil-alt {
    color: rgb(6, 87, 6);
}

.main_table tbody tr td i.fa-trash-alt {
    color: rgb(167, 50, 29);
}

.Mstatus {
    text-align: center;
}

.Mstatus p {
    background: rgb(6, 87, 6);
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
}

.MstatusRed p {
    background: rgb(211, 20, 20);
}

.user_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 80px auto;
}

.user_pagination button {
    background: #fff;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: 5px;
    margin: 0px 7px;
    line-height: 45px;
    border: 1px solid #dfdfdf;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.5);
    color: #000;
}

.user_pagination button.activated {
    background-color: #D057A6;
    color: #fff;
}
/* .min_loader{
    min-height: 100vh;
} */