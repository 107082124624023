.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.get_all_images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: self-start;
}

.image_upload {
  width: 26%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image_upload img {
  width: 100%;
  margin-right: 10px;
  height: 15vh;
  object-fit: cover;
}

.images_upload_main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 35px;
}

.not_found {
  padding: 50px;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blogBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.not_found img {
  width: 50%;
}

.not_found .not_logo {
  margin-bottom: 15px;
  width: 240px;
}

.not_found button {
  margin-top: 25px;
}

.dash_QuickLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  width: 70%;
  margin: auto;
}
.dash_inner{
  text-align: center;
  width: 100%;
}
.dash_inner h1{
  margin-bottom: 40px;
  font-size: 62px;
}
.dash_flex{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  max-width: 700px;
  margin: auto;
}
.dash_fInner{
  width: 29%;
  margin: 2%;
}
.dash_fInner button{
  width: 100%;
}
.dash_fInner button i{
  margin-left: 10px;
}

@media screen and (max-width: 1092px) {
  .not_found img {
    width: 100%;
  }

  .not_found .not_logo {
    width: 180px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}