.inter_inner{
    padding: 5%;
}
.form_field{
    margin: 25px 0px;
}
.form_field label{
    margin-bottom: 15px;
    margin-left: 5px;
}
.submit_sec{
    margin: 30px auto;
    text-align: right;
}