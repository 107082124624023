.main_sidebar .sidebar_top {
    padding: 30px;
    text-align: center;
    background: #F4F0FD;
}

.New_drop {
    width: 100%;
    padding: 20px 30px;
}

.New_drop i.fab.fa-accusoft {
    margin-right: 8px;
    color: #D057A6;
    display: flex;
    align-items: center;
}

.main_sidebar .sidebar_top img {
    width: 180px;
    margin-bottom: 15px;
}

.main_sidebar .sidebar_top h6 {
    background: #D057A6;
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.main_sidebar .sidebar_body ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.main_sidebar .sidebar_body ul li {
    border-bottom: 1px solid #dfdfdf;
    background: #eff7fd;
}

.main_sidebar .sidebar_body ul li.activated {
    border-bottom: 1px solid #dfdfdf;
    background: #D057A6;
    transition: 4s;
}

.main_sidebar .sidebar_body ul li a {
    text-decoration: none;
    color: inherit;
    font-size: 18px;
    display: block;
    width: 100%;
    padding: 20px 30px;
}

.main_sidebar .sidebar_body ul li.activated a i {
    color: #fff;
}

.main_sidebar .sidebar_body ul li.activated a {
    color: #fff;
}

.main_sidebar .sidebar_body ul li a i {
    margin-right: 8px;
    color: #D057A6;
}

.main_sidebar .sidebar_body ul li:first-of-type {
    border-top: 1px solid #dfdfdf;
}

.filters_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 20px 30px;
    border-bottom: 1px dashed #dfdfdf;
}

.filtersL {
    width: 30%;
}

.filtersL input {
    max-width: 350px;
    margin-top: 10px;
}

.filtersR {
    width: 30%;
}

.filtersR select {
    max-width: 350px;
    margin-left: auto;
    padding: 15px;
    margin-top: -10px;
}