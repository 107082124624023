.login_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    background: url(../images/abstract.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.login_nav {
    width: 100%;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    padding-top: 20px;
    position: absolute;
    top: 0;
}

.site_logo {
    width: 120px;
}

.loginL {
    width: 50%;
}

.loginL img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.loginR {
    width: 50%;
    padding: 10%;
}

.login_formSec h1 {
    margin-bottom: 25px;
}

/* .login_formSec {
    max-width: 80%;
} */

.login_formSec button {
    width: 100%;
}

.main_button {
    background: linear-gradient(to right, #D057A6, #a83a82);
    color: #fff;
    padding: 10px 25px;
    border-radius: 5px;
    outline: none;
    border: none;
}