.main_proflieSec {
    padding: 60px 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.profileFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profileFlexL {
    width: 25%;
    position: relative;
}

.gender_iop {
    background: ghostwhite;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    position: absolute;
    padding: 10px 17px;
    top: 2%;
    left: 3%;
}

.gender_iop i {
    color: #FF72A7;
    font-size: 18px;
}

.profileFlexL img {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    object-fit: cover;
    border-top: 6px solid #8C6DCB;
    border-bottom: 6px solid #8C6DCB;
    border-left: 6px solid #FF72A7;
    border-right: 6px solid #FF72A7;
    position: relative;
    animation: change 4s infinite linear;
}

.profileFlexR {
    width: 75%;
}

.profile_title h2 {
    color: #333333;
    text-transform: capitalize;
}

.profile_title h2 i {
    color: rgb(11, 138, 11);
    font-size: 18px;
    position: relative;
    top: -15px;
}

.profile_title p:first-of-type {
    font-size: 18px;
}

.profile_title p:first-of-type i {
    font-size: 15px;
}

.profile_title p {
    margin-bottom: 5px;
}

.profile_title p i {
    color: #8C6DCB;
}

.profile_descriptionSec {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    margin-top: 40px;
    position: relative;

}

.profile_descriptionSec {
    padding: 30px;
}

.profile_dflex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.profile_dFlexL {
    width: 40%;
    margin-right: 10%;
    position: relative;
}

.profile_dFlexL::after {
    content: '';
    background: linear-gradient(to bottom, #9D6DD3, #FF72A7);
    width: 3px;
    height: 90%;
    position: absolute;
    top: 5%;
    right: -15%;
}

.profileEntry p {
    margin: 0;
    text-transform: capitalize;
    word-break: break-all;
}

.profileEntry p:first-of-type {
    font-weight: 600;
    width: 40%;
    text-align: left;
}

.profileEntry p {
    width: 20%;
    text-align: center;
}

.profileEntry p:last-of-type {
    width: 40%;
    text-align: right;
}

.profileEntry p i {
    color: #8C6DCB;
    margin-right: 5px;
}

.profile_dFlexR {
    width: 40%;
}

.profileEntry {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
}

.verified_badge {
    position: absolute;
    right: 0;
    top: 0;
}

.verified_badge img {
    width: 120px;
}

@keyframes change {
    0% {
        border-top: 6px solid #8C6DCB;
        border-bottom: 6px solid #8C6DCB;
        border-left: 6px solid #FF72A7;
        border-right: 6px solid #FF72A7;
    }

    50% {
        border-top: 6px solid #FF72A7;
        border-bottom: 6px solid #FF72A7;
        border-left: 6px solid #8C6DCB;
        border-right: 6px solid #8C6DCB;
    }

    100% {
        border-top: 6px solid #8C6DCB;
        border-bottom: 6px solid #8C6DCB;
        border-left: 6px solid #FF72A7;
        border-right: 6px solid #FF72A7;
    }
}

@media screen and (max-width: 767px) {

    .profileFlex {
        flex-direction: column;
    }

    .profileFlexL {
        width: 100%;
        position: relative;
        text-align: center;
        margin-bottom: 30px;
    }

    .gender_iop {
        top: 2%;
        left: 28%;
    }


    .profileFlexR {
        width: 100%;
    }

    .profile_title h2 i {
        font-size: 14px;
    }

    .profile_dflex {
        flex-direction: column;
    }

    .profile_dFlexL {
        width: 100%;
        margin-right: 0%;
        position: relative;
    }

    .profile_dFlexL::after {
        display: none;
    }


    .profile_dFlexR {
        width: 100%;
    }

    .verified_badge {
        position: absolute;
        right: -0%;
        top: -10%;
    }

    .verified_badge img {
        width: 70px;
    }


}