@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Outfit', sans-serif !important;
}

button,
a,
span,
div {
  font-family: 'Outfit', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  font-family: 'Outfit', sans-serif;
}

.main_flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}

.main_flexL {
  width: 20%;
  min-height: 100vh;
  height: 100%;
  position: sticky;
  top: 0;
}

.main_flexR {
  width: 80%;
  border-left: 1px solid #dfdfdf;
  min-height: 100vh;
  position: relative;
}

.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
}

.main_flexR .right_title {
  background: #EFF7FD;
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
  padding: 25px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_flexR .right_title h1 {
  margin: 0;
  text-transform: capitalize;
}

.main_flexR .right_title i {
  color: rgb(206, 81, 58);
  font-size: 22px;
  cursor: pointer;
}